.MTableToolbar-root-41{
   display:none!important;
}

.MuiTableCell-root {
   padding: 3px 15px!important;
}

.MuiTableRow-root{
  height: 50px!important;
}