
    .MuiTypography-h4 {
      font-size: calc(1.5rem + (34 - 24) * ((100vw - 400px) / (1366 - 400)));
      min-height: 0vw;
    }
  

    .MuiTypography-h4 {
      font-size: 34px;
    }
  